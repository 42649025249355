import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  title: {
    scrollMarginTop: '170px',
    marginTop: '40px',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      color: '#222222',
      fontSize: 24,
      fontFamily: 'Inter',
      fontWeight: '600'
    }
  },
  content: {
    lineHeight: '28px',
    marginTop: 0,
    fontSize: 16,
    fontWeight: '400',
    '& > :first-child': {
      marginTop: 0
    },
    '& > * > img': {
      width: '100%'
    },
    '& > * > * > img': {
      width: '100%'
    },
    '& > h1': {
      color: '#222222',
      fontSize: 24,
      fontFamily: 'Inter',
      fontWeight: '700'
    },
    '& > h2': {
      color: '#222222',
      fontSize: 20,
      fontFamily: 'Inter',
      fontWeight: '700'
    },
    '& > h3': {
      color: '#222222',
      fontSize: 18,
      fontFamily: 'Inter',
      fontWeight: '700'
    },
    '& > h4': {
      color: '#222222',
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '700'
    },
    '& > h5': {
      color: '#222222',
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '600'
    },
    '& > h6': {
      color: '#222222',
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '600'
    },
    '& > p': {
      color: '#222222',
      fontSize: 16,
      fontWeight: '400'
    }
  },
  image: {
    width: '100%',
    borderRadius: 16,
    marginTop: '48px',
    marginBottom: '-20px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '32px',
      marginBottom: '-48px'
    }
  }
}));

const ContentSection = ({ id, title, content, image }) => {
  const classes = useStyles();

  return (
    <>
      {image?.url && <img src={image.url} alt={image.alt} className={classes.image} />}
      {title && <RichText externalClassName={classes.title} html={title} id={id} />}
      <RichText externalClassName={classes.content} html={content} />
    </>
  );
};

ContentSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

ContentSection.defaultProps = {
  image: null
};

export default ContentSection;
